<template>
  <div
    class="d-flex flex-column justify-content-center w-100 align-items-center common__container position-relative w-100"
  >
    <Navbar />
    <div class="common__container__right w-100 h-100">
      <div class="w-100 heading__container d-flex flex-column">
        <div>
          <h2 class="heading">Enter the skills required for your role</h2>
          <h4 class="subheading mt-md-1">
            These are your proficiencies as required in the role, not to confuse
            with your skills entered in the talent profile. The program will
            evaluate development priority by subtracting skills you already have
            from the skills you 'need' to succeed in the role. Unlock a world of
            tailored learning resources curated just for you. Take the step
            towards achieving your development goals today.
          </h4>
        </div>
      </div>

      <div
        class="common__card d-flex flex-column flex-lg-row"
        style="gap: 1rem"
      >
        <div class="skills__container__header w-100">
          <div
            class="d-flex flex-column py-50 py-md-75"
            style="width: fit-content"
            v-if="!loading"
          >
            <main
              class="dropdown mt-50 mt-md-75"
              v-click-outside="toggleListVisibility"
            >
              <b-form-input
                size="lg"
                class="dropdown__search"
                type="text"
                v-model="skill"
                @input="searchSkill()"
                @focus="toggleListVisibility(true)"
                placeholder="Enter Skill"
                autocomplete="off"
              />

              <vue-perfect-scrollbar
                class="dropdown__content"
                :class="listVisible ? 'd-block position-absolute' : 'd-none'"
              >
                <ul v-if="filteredSkills.length > 0" class="p-0 my-50">
                  <li
                    class="d-flex align-items-center"
                    v-for="(skill, index) in filteredSkills"
                    @click="selectSkill(skill)"
                    :key="index"
                  >
                    <div class="d-flex user-name">
                      {{ skill }}
                    </div>
                  </li>
                </ul>

                <ul v-if="filteredSkills.length === 0" class="p-0 my-50">
                  <li class="px-75" @click="selectSkill(skill)">
                    <div class="d-flex user-name">
                      {{ skill }}
                    </div>
                  </li>
                </ul>
              </vue-perfect-scrollbar>
            </main>
          </div>

          <div class="d-flex flex-wrap w-100" v-if="loading">
            <b-skeleton
              class="mr-1 mt-1 px-7 rounded-pill"
              type="button"
              v-for="i in 10"
              :key="i"
            />
          </div>

          <div v-else>
            <div class="skills-set mt-2" v-if="selectedSkillsArray.length > 0">
              <h2 v-if="isDevelopedSkill" class="common__subheading mb-75">
                By marking a skill as a 5, you are listing it as something that
                you are confident in and do not need to develop in at this time.
              </h2>
              <div v-if="isDevelopedSkill" class="d-flex flex-wrap mb-50">
                <div
                  v-for="(skill, i) in selectedSkillsArray"
                  :key="i"
                  class="items-center selected__skill px-1 py-50 cursor-pointer mr-75 mb-75"
                  :class="[
                    skill.rating === 5 ? 'd-flex' : 'd-none',
                    skill.skill === selectedSkill ? 'position-relative' : '',
                  ]"
                  @mouseenter="skillHovered = skill.skill"
                  @mouseleave="skillHovered = ''"
                  @click="selectSkill(skill.skill, skill.rating)"
                >
                  <h4 class="m-0">
                    {{ skill.skill }}
                  </h4>

                  <span
                    v-if="skill.skill === skillHovered"
                    class="selected__skill__rate selected__skill__rate__active good__rate__active"
                  >
                    <img
                      src="@/assets/images/icons/base-active-skill-star.svg"
                    />
                    <span>{{ skill.rating }}</span>
                  </span>

                  <span
                    v-else
                    class="selected__skill__rate selected__skill__rate good__rate"
                  >
                    <img
                      src="@/assets/images/icons/base-skill-star.svg"
                      @click="selectSkill(skill.skill, skill.rating)"
                    />
                    <span>{{ skill.rating }}</span>
                  </span>

                  <span
                    v-if="skill.skill === skillHovered"
                    @click.stop="removeSkill(i)"
                    class="selected__skill__rate__remove"
                  >
                    <img src="@/assets/images/icons/remove-skill-star.svg" />
                  </span>
                  <!-- rate skill modal  -->
                  <div
                    class="common__card rate__skill__card position-absolute mt-1"
                    v-if="rateSkillModal && skill.skill === selectedSkill"
                  >
                    <h2 class="common__heading m-0">Rate this skill</h2>
                    <hr />
                    <div
                      class="d-flex flex-column align-items-center justify-content-center py-2"
                    >
                      <h2 class="common__heading m-0">
                        {{ skillLevel }}
                      </h2>
                      <span class="my-2">
                        <img
                          class="cursor-pointer star mx-25"
                          @mouseover="rateSkill(i)"
                          @mouseout="rateSkill(0)"
                          @click.stop="
                            selectSkill(skill.skill, i, true);
                            toggleSkillRateModal(false);
                          "
                          v-for="i in 5"
                          :key="i"
                          :src="
                            require(`@/assets/images/icons/${
                              i <= ratingSelected ? 'active' : 'inactive'
                            }-skill-star.svg`)
                          "
                          alt="star"
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Skills marked for development  -->
              <div v-if="isDevelopingSkill">
                <h2 class="common__subheading mb-75">
                  Anything under a 5 will be marked for development as required
                  for the role.
                </h2>
                <div class="d-flex flex-wrap">
                  <div
                    v-for="(skill, i) in selectedSkillsArray"
                    :key="i"
                    class="items-center selected__skill px-1 py-50 cursor-pointer mr-75 mb-75"
                    :class="[
                      skill.rating !== 5 ? 'd-flex' : 'd-none',
                      skill.skill === selectedSkill ? 'position-relative' : '',
                    ]"
                    @click="selectSkill(skill.skill, skill.rating)"
                    @mouseenter="skillHovered = skill.skill"
                    @mouseleave="skillHovered = ''"
                  >
                    <h4 class="m-0">
                      {{ skill.skill }}
                    </h4>

                    <span
                      v-if="skill.skill === skillHovered"
                      class="selected__skill__rate selected__skill__rate__active bad__rate__active"
                    >
                      <img
                        src="@/assets/images/icons/base-active-skill-star.svg"
                      />
                      <span>{{ skill.rating }}</span>
                    </span>
                    <span
                      v-else
                      class="selected__skill__rate selected__skill__rate bad__rate"
                    >
                      <img
                        src="@/assets/images/icons/bad-skill-star.svg"
                        @click="selectSkill(skill.skill, skill.rating)"
                      />
                      <span>{{ skill.rating }}</span>
                    </span>
                    <span
                      v-if="skill.skill === skillHovered"
                      @click.stop="removeSkill(i)"
                      class="selected__skill__rate__remove"
                    >
                      <img src="@/assets/images/icons/remove-skill-star.svg" />
                    </span>

                    <!-- rate skill modal  -->
                    <div
                      class="common__card rate__skill__card position-absolute"
                      v-if="rateSkillModal && skill.skill === selectedSkill"
                    >
                      <h2 class="common__heading m-0">Rate this skill</h2>
                      <hr />
                      <div
                        class="d-flex flex-column align-items-center justify-content-center"
                      >
                        <h2 class="common__heading m-0">
                          {{ skillLevel }}
                        </h2>
                        <span class="my-2">
                          <img
                            class="cursor-pointer star mx-25"
                            @mouseover="rateSkill(i)"
                            @mouseout="rateSkill(0)"
                            @click.stop="
                              selectSkill(skill.skill, i, true);
                              toggleSkillRateModal(false);
                            "
                            v-for="i in 5"
                            :key="i"
                            :src="
                              require(`@/assets/images/icons/${
                                i <= ratingSelected ? 'active' : 'inactive'
                              }-skill-star.svg`)
                            "
                            alt="star"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              v-else
              class="w-100 d-flex flex-column justify-content-center align-items-center"
            >
              <div>
                <img
                  src="@/assets/images/pages/skills-illustration.svg"
                  alt="Skills"
                />
              </div>

              <h2 class="common__subheading mt-2">
                Can't find your skills? No worries, simply enter them below to
                unlock personalized learning resources tailored just for you.
              </h2>
            </div>
          </div>
        </div>

        <div />
      </div>
    </div>
  </div>
</template>

<script>
import { BFormInput, BSkeleton } from "bootstrap-vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import allSkills from "@/assets/data/skills";
import Navbar from "@/components/Navbar.vue";

export default {
  components: {
    Navbar,
    BFormInput,
    BSkeleton,
    VuePerfectScrollbar,
  },
  data() {
    return {
      allSkills,
      skill: "",
      listVisible: false,
      filteredSkills: [],
      selectedSkillsArray: [],
      ratingSelected: 5,
      skillLevel: "Expert",
      rateSkillModal: false,
      selectedSkill: "",
      skillHovered: "",
      isDevelopedSkill: false,
      isDevelopingSkill: false,
      stepId: "ROLE_SKILLS",
      loading: true,
    };
  },
  directives: {
    "click-outside": {
      bind(el, binding, vnode) {
        el.clickOutsideEvent = function (event) {
          if (!(el === event.target || el.contains(event.target))) {
            vnode.context[binding.expression]();
          }
        };
        document.body.addEventListener("click", el.clickOutsideEvent);
      },
      unbind(el) {
        document.body.removeEventListener("click", el.clickOutsideEvent);
      },
    },
  },
  computed: {
    isUserLoggedIn() {
      return this.$store.state.auth.isUserLoggedIn;
    },
    userData() {
      return this.$store.state.user.userData;
    },
    isSkillStepUnlocked() {
      return this.$store.state.user.isSkillStepUnlocked;
    },
    isUserAvatarError() {
      return this.$store.state.user.isUserAvatarError;
    },
  },
  watch: {
    ratingSelected: {
      handler(newVal) {
        if (newVal < 3) {
          this.skillLevel = "Novice";
        } else if (newVal >= 3 && newVal < 5) {
          this.skillLevel = "Intermediate";
        } else {
          this.skillLevel = "Advanced";
        }

        switch (newVal) {
          case 1:
            this.skillLevel = "Novice";
            break;
          case 2:
            this.skillLevel = "Beginner";
            break;
          case 3:
            this.skillLevel = "Intermediate";
            break;
          case 4:
            this.skillLevel = "Proficient";
            break;
          default:
            this.skillLevel = "Expert";
        }
      },
    },
    rateSkillModal: {
      handler(newVal) {
        if (!newVal) {
          this.selectedSkill = "";
          this.ratingSelected = 5;
        }
      },
    },
    selectedSkillsArray: {
      immediate: true,
      deep: true,
      handler(newVal) {
        if (newVal && newVal.length > 0) {
          for (let i = 0; i < newVal.length; i++) {
            if (newVal[i].rating < 5) {
              this.isDevelopingSkill = true;
            } else if (newVal[i].rating === 5) {
              this.isDevelopedSkill = true;
            }
          }
        }
      },
    },
  },
  created() {
    this.getUserSkills();
  },
  mounted() {
    this.filteredSkills = allSkills;
  },
  methods: {
    toggleListVisibility(value) {
      this.listVisible = value;
      if (!value) this.skill = "";
    },
    toggleSkillRateModal(val) {
      if (val !== this.rateSkillModal) {
        this.rateSkillModal = val;
        if (this.rateSkillModal) {
          setTimeout(() => {
            document.addEventListener("click", this.handleGlobalClick);
          });
        } else {
          document.removeEventListener("click", this.handleGlobalClick);
        }
      }
    },
    handleGlobalClick(event) {
      const componentElement = this.$el.querySelector(".rate__skill__card");
      const pills = this.$el.querySelectorAll(".selected__skill");
      if (!componentElement.contains(event.target)) {
        let isPillClicked = false;
        pills.forEach((pill) => {
          if (pill.contains(event.target)) {
            isPillClicked = true;
          }
        });
        if (!isPillClicked) {
          this.toggleSkillRateModal(false);
        }
      }
    },
    getUserSkills() {
      this.loading = true;

      this.$store
        .dispatch("user/fetchUserSkills", {
          userId: this.userData._id || this.$route.query.gbuid,
          orgId: this.$route.query.gborgid,
        })
        .then((res) => {
          if (res?.data?.skills.length > 0) {
            for (let i = 0; i < res.data.skills.length; i++) {
              const skillTempObj = {
                skill: res.data.skills[i].title,
                rating: res.data.skills[i].level,
              };
              this.selectedSkillsArray.push(skillTempObj);
            }
          }
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false;
        });
    },
    searchSkill() {
      this.filteredSkills = allSkills.filter((skill) => {
        return skill.toLowerCase().startsWith(this.skill);
      });
    },
    rateSkill(i) {
      if (i) {
        this.ratingSelected = i;
      }
    },
    selectSkill(skill, rating, isRatingUpdated) {
      if (!rating) {
        this.updateSkillRating(skill, 5, true);
      } else {
        this.updateSkillRating(skill, rating, isRatingUpdated);
      }
      this.toggleSkillRateModal(true);
      this.toggleListVisibility(false);
      this.searchSkill();
    },
    removeSkill(index) {
      this.selectedSkillsArray.splice(index, 1);
      this.updateSkillRating(null, null, true);
    },
    updateSkillRating(skill, rating, isRatingUpdated) {
      if (skill) {
        this.selectedSkill = skill;
      }
      if (rating) {
        this.ratingSelected = rating;
      }
      const foundSkillIndex = this.selectedSkillsArray.findIndex(
        (obj) => obj.skill === this.selectedSkill
      );
      if (foundSkillIndex !== -1) {
        this.selectedSkillsArray[foundSkillIndex].rating = this.ratingSelected;
      } else {
        if (this.selectedSkill && rating) {
          const skillObj = {
            skill: this.selectedSkill,
            rating: rating,
          };
          this.selectedSkillsArray.push(skillObj);
        }
      }
      if (isRatingUpdated) {
        let skillsArray = [];
        for (let i = 0; i < this.selectedSkillsArray.length; i++) {
          const skillTempObj = {
            title: this.selectedSkillsArray[i].skill,
            level: this.selectedSkillsArray[i].rating,
          };

          if (this.selectedSkillsArray[i].rating < 5) {
            skillTempObj.activateLearning = true;
          }
          skillsArray.push(skillTempObj);
        }
        const payload = {
          gtbrddUserId: this.userData._id,
          gtbrddOrganizationId: this.$route?.query?.gborgid,
          skills: skillsArray,
        };
        this.$store
          .dispatch("user/updateUserSkills", payload)
          .then(() => {})
          .catch(() => {});
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.skills__container {
  &__header {
    .common__heading {
      font-size: 1.75rem;
    }

    hr {
      border-top: 2px solid #eaecf0;
      margin: 1em -3em;
    }

    .skills-set {
      .selected__skill {
        border: 2px solid #d0d5dd;
        border-radius: 2rem;
        text-transform: capitalize;
        width: max-content;
        display: flex;
        gap: 1rem;
        height: fit-content;
        align-items: center;
        position: relative;

        h4 {
          color: #475467;
          line-height: 0rem;
        }

        &__rate {
          display: flex;
          height: fit-content;
          align-items: center;
          gap: 0.8rem;
          padding: 0.3rem 0.7rem;
          border-radius: 1rem;

          img {
            width: 1.3rem;
            height: auto;
          }

          span {
            line-height: 0rem;
            font-size: 1.25rem;
            font-weight: 700;
          }
        }

        .good__rate {
          background-color: rgb(37, 204, 154, 0.1);

          span {
            color: #25cc9a;
          }
        }

        .good__rate__active {
          background-color: #25cc9a;

          span {
            color: white;
          }
        }

        .bad__rate {
          background-color: rgb(255, 101, 132, 0.1);

          span {
            color: #ff6584;
          }
        }

        .bad__rate__active {
          background-color: #ff6584;

          span {
            color: #ffffff;
          }
        }

        &__rate__remove {
          position: absolute;
          right: 0rem;
          background-color: white;
          padding: 0.6rem;
          border-radius: 2rem;

          img {
            width: 1.7rem;
            height: auto;
            opacity: 1;
            background-color: white;
          }
        }
      }

      .selected__skill:hover {
        background-color: #f2f4f7;
      }

      .rate__skill__card {
        margin: 0;
        top: 3em;
        width: max-content;
        cursor: default;
        z-index: 10;

        .common__heading {
          font-weight: 600;
          font-size: 1.25rem;
        }

        .star {
        }
      }
    }
  }
}

@media only screen and (max-width: 991px) {
  .skills__container {
    &__header {
      .common__heading {
        font-size: 1.5rem;
      }

      hr {
        margin: 0.75em -1.5em;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .skills__container {
    &__header {
      .common__heading {
        font-size: 1.25rem;
      }

      hr {
        margin: 0.5em -1.5em;
      }
    }
  }
}

.custom-select-lg:focus,
.form-control:focus,
.input-group-lg::v-deep input:focus {
  border-color: var(--app-theme-color) !important;
}
</style>
